.breadcrumb
    position: relative
    display: flex
    align-items: center
    @media(max-width: 1023px)
        display: none
    svg
        display: block
        height: 8px
        width: auto
        fill: $bodyColor
        margin: 0 .8rem
    a
        text-decoration: none
        color: $bodyColor
        &:hover
            color: $dark
        
    .current-page,
    span
        color: $brand
        
        