.navigation
    position: relative
    padding: 1rem 0
    transition: background-color .4s ease
    &.absolute
        position: absolute
        width: 100%
        z-index: 400
        @media(max-width: 767px)
            position: fixed
    
    &__container
        @extend .container
        display: flex
        align-items: center
        justify-content: space-between
            
    
    &__mobile
        display: none
        @media(max-width: 1023px)
            display: flex
            position: fixed
            z-index: 800
            top: 0
            left: 0
            width: 100%
            height: 100vh
            background: $white
            flex-direction: column
            align-items: center
            justify-content: center
            transform: translateY(-100%)
            transition: transform .4s ease
            
            &.open
                transform: translateY(0%)
                transition: transform .4s ease
            
            ul
                width: 300px
                padding: 0
                margin: 0
                list-style: none
                li
                    padding: .4rem
                    position: relative
                    a
                        text-decoration: none
                        font-size: 1.4rem
                        font-weight: 500
                        position: relative
                        color: $dark
                        
                        .count
                            position: absolute
                            font-size: .8rem
                            height: 16px
                            width: 16px
                            border-radius: 8px
                            background: $brand
                            left: -.4rem
                            top: 0
                            color: $white
                            display: flex
                            align-items: center
                            justify-content: center
                    .sub-menu
                        li
                            a
                                font-size: 1.2rem
        &--trigger
            display: none
            @media(max-width: 1023px)
                display: flex
                position: relative
                height: 40px
                width: 40px
                justify-content: center
                align-items: center
                flex-direction: column
                z-index: 880
                
                span
                    position: relative
                    width: 100%
                    height: 3px
                    background: $dark
                    border-radius: 2px
                    transition: background .3s ease
                    
                    &::before
                        content: ''
                        position: absolute
                        top: -10px
                        height: 100%
                        width: 100%
                        background: $dark
                        border-radius: 2px
                        transition: top .2s ease .2s, transform .2s ease
    
                    &::after
                        content: ''
                        position: absolute
                        top: 10px
                        height: 100%
                        width: 100%
                        background: $dark
                        border-radius: 2px
                        transition: top .2s ease .2s, transform .2s ease
                &.open
                    span
                        background: transparent
                        transition: background .1s ease
                        &::before
                            top: 0
                            transform: rotate(45deg)
                            transition: top .2s ease, transform .2s ease .2s
                            background-color: $dark
    
                        &::after
                            top: 0
                            transform: rotate(-45deg)
                            transition: top .2s ease, transform .2s ease .2s
                            background-color: $dark
        
    
    &__box
        flex: 1
        display: flex
        width: 100%
        &:last-of-type,
        &.last
            justify-content: flex-end
        @media(max-width: 1023px)
            display: none
            
        &.center
            justify-content: center
            @media(max-width: 1023px)
                display: block
        .logo
            a
                text-decoration: none
                svg
                    transition: height .4s ease
                    display: block
                    height: 100px
                    width: auto
                    @media(max-width: 1023px)
                        height: 80px
        ul
            list-style: none
            padding: 0
            margin: 0
            display: flex
            align-items: center
            
            li
                margin: 0 1rem
                position: relative
                z-index: 400
                &:last-of-type
                    margin: 0 0 0 1rem
                
                &:first-of-type
                    margin: 0 1rem 0 0
                    
                a
                    text-decoration: none
                    display: flex
                    align-items: center
                    font-weight: 500
                    position: relative
                    z-index: 200
                    svg
                        height: 24px
                        width: auto
                        display: block
                        margin-right: .8rem
                        fill: $bodyColor
                        
                    .count
                        position: absolute
                        top: -4px
                        left: -4px
                        width: 16px
                        height: 16px
                        border-radius: 8px
                        background: $brand
                        display: flex
                        align-items: center
                        justify-content: center
                        font-size: .8rem
                        color: $white
                &:hover,
                &.current,
                &.current-menu-item
                    color: $brand
                    svg
                        fill: $brand
                        
                .sub-menu
                    position: absolute
                    padding-top: 2rem
                    display: none
                    grid-template-columns: repeat(1, 1fr)
                    top: 0
                    left: 50%
                    transform: translateX(-50%)
                    z-index: 1
                    &::before
                        content: ''
                        position: absolute
                        top: 1.5rem
                        left: calc(50% - 10px)
                        width: 0
                        height: 0
                        border-left: 8px solid transparent
                        border-right: 8px solid transparent
                        border-bottom: 8px solid $white
                    &::after
                        content: ''
                        position: absolute
                        bottom: 0
                        left: 0
                        height: calc(100% - 2rem)
                        width: 100%
                        background: transparent
                        box-shadow: 0px 16px 16px rgba($black, .2)
                    li
                        display: block
                        margin: 0
                        text-align: left
                        background: $white
                        padding: 0 1rem
                        color: $black
                        min-width: 240px
                        &:first-of-type
                            border-top-left-radius: 4px
                            border-top-right-radius: 4px
    
                        &:last-of-type
                            border-bottom-left-radius: 4px
                            border-bottom-right-radius: 4px
                        &:hover
                            color: $brand
                            
                        a
                            display: block
                            padding: 1rem 0
                            border-bottom: 1px solid $light
                        
                &:hover
                    .sub-menu
                        display: grid
                        
    
    @media(max-width: 767px)
        position: fixed
        width: 100%
        z-index: 400
        &.scrolled
            background-color: $white
            transition: background-color .4s ease
            .logo
                svg
                    height: 60px
                    transition: height .4s ease