form.wpcf7-form
    max-width: 600px
    margin: 0px auto

.form
    position: relative
    
    &__group
        display: flex
        justify-content: flex-start
        
    &__field
        flex: 1
    
label
    font-size: .8rem
    margin: .8rem 0
    display: block
        
input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"],
select,
textarea
    appearance: none
    padding: .6rem
    border: 2px solid $light
    border-radius: 4px
    background: $white
    font-size: 1rem
    display: block
    width: 100%
    &:focus,
    &:active,
    &:hover,
    &.active
        outline: none
        box-shadow: none
        border-color: $brand
    
input[type="checkbox"]
    width: auto
    display: inline-block
    

button,
.button
    appearance: none
    background: $brand
    padding: .6rem
    font-size: 1rem
    border-radius: 4px
    border: 2px solid $brand
    color: $white
    font-weight: 500
    cursor: pointer
    
    &.secondary
        background: transparent
        color: $brand
        border: 2px solid $brand
    
    &.success
        background: $green
        border: 2px solid $green
    
    
input[type="submit"]
    @extend .button