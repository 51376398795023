.thanks
    position: relative
    
    &__container
        @extend .container
        margin: 2rem auto
        h3
            font-size: 2rem
        
    .order
        position: relative
        display: block
        width: 100%
        margin: 1rem 0
        
        &__items
            display: block
            border: 2px solid $light
            border-radius: 4px
            
        &__item
            border-bottom: 2px solid $light
            display: grid
            grid-template-columns: repeat(4, 1fr)
            grid-column-gap: 1rem
            &:last-of-type
                border-bottom: unset
                
            @media(max-width: 767px)
                display: flex
                flex-wrap: wrap
                
            
            &--column
                padding: .4rem
                @media(max-width: 767px)
                    width: 33.33%
                    padding: .2rem .4rem
                    &:nth-of-type(1)
                        width: 100%
                        
                @media(max-width: 400px)
                    width: 100%
                    display: flex
                    justify-content: space-between
                small
                    display: block
                    color: rgba($dark, .6)
                    
                    
        &__cart
            max-width: 400px
            margin: 2rem 0 2rem auto
            
            &--row
                display: flex
                justify-content: space-between
                
        