.footer
    position: relative
    background: $footerBackgroundColor
    padding: 4rem 0 1rem 0
    color: $footerColor
    &__columns
        @extend .container
        display: flex
        justify-content: space-between
        @media(max-width: 767px)
            flex-wrap: wrap
        
    &__column
        width: 25%
        @media(max-width: 767px)
            width: 100%
            margin-bottom: 2rem
        strong
            display: block
            margin: .4rem 0
            font-weight: 500
        ul
            list-style: none
            padding: 0
            margin: .4rem 0
            li
                a
                    text-decoration: none
        .links
            display: flex
            align-items: center
            justify-content: flex-start
            margin: .4rem 0
            a
                text-decoration: none
                margin: 0 .4rem
                svg
                    display: block
                    height: 24px
                    width: auto
                    fill: $dark
                    
    &__payments
        @extend .container
        display: flex
        align-items: center
        justify-content: space-around
        margin: 4rem auto
        @media(max-width: 767px)
            flex-wrap: wrap
            margin: 1rem auto
        span
            opacity: .2
            @media(max-width: 767px)
                opacity: .1
            svg
                display: block
                height: 32px
                width: auto
                @media(max-width: 767px)
                    height: 28px
                    margin: 1rem .4rem
                
        
    &__disclaimer
        @extend .container
        font-size: .8rem
        color: $grey