.cart
    &__container
        @extend .container
        padding: 2rem 0 4rem
        
        h1
            font-size: 4rem
        
        .woocommerce
            .woocommerce-notices-wrapper
                display: none
            .product-thumbnail
                img
                    max-height: 40px
                    width: auto
                    
            .actions .coupon
                display: flex
                justify-content: flex-start
                align-items: center
                label
                    display: none
                    
                input#coupon_code
                    min-width: 160px
                    margin-right: .8rem
            .cart_item
                .units-info,
                .unit-price-cart
                    color: rgba($dark, .4)
                    font-size: .8rem
                    margin: 0
            .cart_totals
                h2
                    font-size: 2rem
                    
                .wc-proceed-to-checkout
                    .checkout-button
                        @extend .button