@import url('https://fonts.googleapis.com/css?family=Amatic+SC:700|Poppins:400,500&display=swap')
@import '../../node_modules/swiper/swiper.min.css'

//colors
$brand: #D5B3A7
$green: #B1DE89
$red: #F56262
$white: #fff
$light: #EEF0F1
$grey: #7D7F81
$dark: #353637
$black: #000

$bodyBackgroundColor: $white
$bodyColor: $dark
$footerBackgroundColor: rgba($dark, .8)
$footerColor: white

*
    box-sizing: border-box
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    &::selection
        background: rgba($brand, .6)
body,
html
    margin: 0px
    padding: 0px
    width: 100%
    overflow-x: hidden

body
    font-family: 'Poppins', sans-serif
    font-size: 1rem
    line-height: 1.6
    font-weight: 400
    color: $bodyColor
    background-color: $bodyBackgroundColor

p
    margin: .4rem 0
    
    
a
    color: inherit
    
strong
    font-weight: 500
    
img
    max-width: 100%
    height: auto
    display: block
    &.alignleft
        float: left
        margin: 2rem 2rem 2rem 0
    
        &::after,
        &::before
            display: table
            content: ''
            clear: both
    
    &.alignright
        float: right
        margin: 2rem 0 2rem 2rem
    
        &::after,
        &::before
            display: table
            content: ''
            clear: both

h1, h2, h3, h4, h5, h6
    font-family: 'Amatic SC', cursive
    font-weight: 700
    margin: 0px
    padding: 0px
mark
    background-color: rgba($brand, .4)
    
.wp-caption
    max-width: 100%
.container
    width: 90%
    margin: 0px auto
    max-width: 1200px
    
    
.notice
    position: fixed
    top: 0
    left: 0
    z-index: 400
    height: 100vh
    width: 100vw
    background-color: rgba($dark, .8)
    display: flex
    align-items: center
    justify-content: center
    
    &__container
        background-color: white
        max-width: 400px
        color: $dark
        
    &__close
        cursor: pointer
    &__header
        display: flex
        flex-direction: row
        align-items: center
        justify-content: space-between
        background-color: $brand
        padding: 1rem
        color: $white
        h2
            font-size: 2rem
            
        
    &__body
        padding: 1rem