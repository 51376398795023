.account
    position: relative
    padding: 2rem 0 4rem
    &__container
        @extend .container
        
        .woocommerce
            display: block
            .woocommerce-ResetPassword
                max-width: 500px
                margin: 2rem auto
            #customer_login
                display: grid
                grid-template-columns: 50% 50%
                grid-column-gap: 4rem
                
                h2
                    font-size: 2rem
                    
                form
                    max-width: 300px
                    margin: 0px
                    
    &.loggedIn
        padding: 1rem 0 4rem
        h1
            font-size: 4rem
            @media(max-width: 1023px)
                font-size: 2rem
        .woocommerce
            display: grid
            grid-template-columns: 240px auto
            grid-column-gap: 4rem
            @media(max-width: 1023px)
                display: block
            
            .woocommerce-MyAccount-navigation
                @media(max-width: 1023px)
                    overflow-x: scroll
                    width: 100%
                    -ms-overflow-style: none
                    scrollbar-width: none
                    overflow: -moz-scrollbars-none
                    &::-webkit-scrollbar
                        display: none
                ul
                    padding: 0
                    margin: 0
                    list-style: none
                    @media(max-width: 1023px)
                        width: 100%
                        display: grid
                        grid-template-columns: repeat(6, 1fr)
                    
                    li
                        padding: .4rem
                        display: block
                        border-bottom: 2px solid $light
                        position: relative
                        &:last-of-type
                            border-bottom: unset
                        @media(max-width: 1023px)
                            border-bottom: unset
                            padding: .8rem
                
                        &::after
                            content: ''
                            background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 10' fill='@{grey}' stroke='none'><path d='m6.225 4.61-4.45-4.45c-.103-.103-.24-.16-.387-.16s-.284.057-.387.16l-.327.327c-.213.213-.213.561 0 .774l3.736 3.737-3.741 3.741c-.103.103-.16.24-.16.387s.057.284.16.387l.328.327c.103.103.24.16.387.16s.284-.057.387-.16l4.454-4.454c.104-.104.16-.241.16-.388s-.057-.285-.16-.388z'/></svg>") no-repeat
                            background-size: 10px 10px
                            height: 10px
                            width: 10px
                            display: block
                            position: absolute
                            right: 0
                            top: calc(50% - 5px)
                            opacity: .2
                            @media(max-width: 1023px)
                                display: none
                        a
                            text-decoration: none
                            font-weight: 500
                            color: rgba($bodyColor, .5)
                            @media(max-width: 1023px)
                                white-space: pre
                
                        &:hover,
                        &.is-active
                            a
                                color: $dark
                            &::after
                                opacity: 1
                    
            .woocommerce-MyAccount-content
                padding: .8rem
                @media(max-width: 1023px)
                    padding: 0
                    width: 100%
                    overflow-x: scroll
                    -ms-overflow-style: none
                    scrollbar-width: none
                    overflow: -moz-scrollbars-none
                    &::-webkit-scrollbar
                        display: none
                form
                    max-width: 500px
                    margin: 0px auto
                    
                    h3
                        font-size: 2rem
                    
                    .woocommerce-address-fields
                        .form-row
                            margin: .4rem 0 .8rem
                            label
                                margin: 0
                p
                    max-width: 500px
                    
                    
                button,
                .button
                    text-decoration: none
                    margin: 0 .8rem 0 0
                .woocommerce-Addresses
                    display: grid
                    grid-template-columns: 50% 50%
                    grid-column-gap: 2rem
                    margin-top: 2rem
                    @media(max-width: 1023px)
                        display: block
                    header
                        h3
                            font-size: 2rem
                    address
                        font-size: .8rem
                    
                .woocommerce-EditAccountForm
                    max-width: 500px
                    margin: 0px auto
                    .form-row
                        margin: .4rem 0 .8rem
                        label
                            margin: 0
                        em
                            font-size: .8rem
                            
                            
                    fieldset
                        margin: 2rem 0
                        display: block
            .woocommerce-MyAccount-orders
                width: 100%
                border: 1px solid $light
                border-radius: 4px
                
                .woocommerce-orders-table__header
                    text-align: left
                    padding: .4rem
                    border-bottom: 1px solid $light
                    
                .woocommerce-orders-table__cell
                    text-align: left
                    padding: .4rem
                    border-bottom: 1px solid $light
                    
                    .button
                        padding: 0
                        margin: 0
                        background: transparent
                        color: $brand
                        border: unset
                    
                .woocommerce-orders-table__row:last-of-type .woocommerce-orders-table__cell
                    border-bottom: unset
            .woocommerce-order-details
                h2
                    font-size: 2rem
                    
            .woocommerce-table--order-details
                margin: .4rem 0 1.6rem
                width: 100%
                
                thead,
                tfoot
                    text-align: left
            .woocommerce-customer-details
                .woocommerce-columns
                    display: grid
                    grid-template-columns: repeat(2, 1fr)
                    grid-column-gap: 2rem
                    @media(max-width: 1023px)
                        display: block
                    h2
                        font-size: 2rem