.notification
    background: $brand
    position: relative
    margin: 0
    padding: 0
    
    &__container
        @extend .container
        text-align: center
        padding: .4rem 0
        p
            margin: 0
            padding: 0
            font-size: .8rem
            color: $white
        