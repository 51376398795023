.post
    position: relative
    border: 1px solid $light
    border-radius: 4px
    overflow: hidden
    display: flex
    flex-direction: column
    justify-content: flex-start
    &__thumbnail
        background-size: cover !important
        background-position: center !important
        background-repeat: no-repeat !important
        height: 160px
        
    &__body
        flex: 1
        padding: .6rem
        display: flex
        flex-direction: column
        justify-content: space-between
        p
            color: $bodyColor
            
        a
            text-decoration: none
            color: $brand
            font-weight: 500
            
            strong
                color: $bodyColor