.claim
    position: relative
    color: $bodyColor
    margin-top: 4rem
    &__container
        @extend .container
        text-align: center
        
        h1
            font-size: 4rem
            margin: 0
            @media(max-width: 767px)
                font-size: 3rem
            
        p
            margin: 0
            
    &__icons
        display: flex
        align-items: center
        justify-content: center
        margin-top: 2rem
        
    &__icon
        text-align: center
        margin: 0rem 4rem
        font-weight: 500
        @media(max-width: 767px)
            margin: 0 1rem
            width: calc(33.33% - 1rem)
        svg
            display: block
            height: 80px
            width: auto
            margin-bottom: 1rem
            max-width: 120px
            fill: $bodyColor
            @media(max-width: 767px)
                height: 40px
                margin: 0 auto .8rem
        