.frontpage
    .content
        @extend .container
        margin: 4rem auto
        
        h2
            font-size: 3rem
            margin-bottom: 2rem
            @media(max-width: 1023px)
                font-size: 2rem
        
        p
            max-width: 1024px
            margin: .4rem auto
                    
    .grid
        @extend .container
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-column-gap: 2rem
        grid-row-gap: 2rem
        grid-template-rows: auto
        margin: 2rem auto 4rem
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)
        &__content
            h2
                font-size: 2rem
                margin-bottom: 1rem
    
            p
                max-width: 720px
                margin: .4rem auto
                