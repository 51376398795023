.blogpost
    position: relative
    padding: 2rem 0 4rem
    @media(max-width: 767px)
        padding-top: 8rem
    &__breadcrumb
        margin: 2rem 0 0
        @media(max-width: 767px)
            font-size: .8rem
    &__container
        @extend .container
    &__title
        h1
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 2rem
    &__excerpt
        max-width: 500px
    &__thumbnail
        margin-top: 2rem
        display: block
        width: 100%
        height: 400px
        background-size: cover !important
        background-position: center !important
        background-repeat: no-repeat !important
        border-radius: 4px
    &__content
        max-width: 720px
        margin: 2rem auto
        clear: both
        &::after,
        &::before
            display: table
            content: ''
            clear: both
        
        h2
            font-size: 2rem
        h3
            font-size: 1.6rem
        blockquote
            padding: 1rem 2rem
            margin: 0 auto
            display: inline-block
            text-align: center
            position: relative
            &::before
                content: '"'
                position: absolute
                top: .4rem
                left: .4rem
                font-size: 3rem
                color: $brand
            &::after
                content: '"'
                position: absolute
                bottom: -1.6rem
                right: .4rem
                font-size: 3rem
                color: $brand

        p
            clear: both
            &::after,
            &::before
                display: table
                content: ''
                clear: both