.standorte
    width: 100%
    height: 800px
    position: relative
    background-color: whitesmoke
    @media(max-width: 1023px)
        height: auto

    .mapIcon
        height: 8px
        width: 8px
        border-radius: 100%
        background-color: dodgerblue
        position: relative
        

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            height: 100%
            width: 100%
            border-radius: 100%
            opacity: .2
            background-color: dodgerblue
            animation: pulse 2s ease-out .3s infinite

        &.dark
            background-color: rgba($black, 1)
            &::after
                background-color: $black
    &__map
        height: 100%
        width: 100%
        z-index: 100
        @media(max-width: 1023px)
            height: 500px
    &__container
        position: absolute
        bottom: 2rem
        right: 2rem
        background-color: white
        z-index: 400
        box-shadow: 0px 4px 16px rgba(0,0,0, .3)
        color: $dark
        @media(max-width: 1023px)
            position: relative
            box-shadow: none
            top: 0
            right: 0
            background-color: $bodyBackgroundColor
            color: $bodyColor
            z-index: 1
            
        &--title
            padding: 16px
            background-color: $brand
            font-size: 24px
            
        &--body
            padding: 16px
            max-height: 420px
            overflow-y: scroll
            overflow-x: hidden
        .meta
            margin: 8px 0px
            padding-bottom: 8px
            border-bottom: 1px solid whitesmoke
            &:last-of-type
                margin: 8px 0px 0px 0px
                border-bottom: none
            strong
                display: block
                margin: 0
                padding: 0
        
        

@keyframes pulse
    0%
        width: 100%
        height: 100%
        opacity: 0
    50%
        width: 400%
        height: 400%
        opacity: 0.4
    100%
        width: 100%
        height: 100%
        opacity: 0
        