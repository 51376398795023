.page
    position: relative
    @media(max-width: 767px)
        padding-top: 8rem
    &__thumbnail
        background-size: cover !important
        background-position: center !important
        background-repeat: no-repeat !important
        height: 50vh
        border-radius: 4px
        margin: 1rem 0
    &__container
        @extend .container
        padding: 1rem 0 4rem
        
    &__content
        clear: both
        &::after,
        &::before
            display: table
            content: ''
            clear: both
        h1
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 2.4rem
        h2
            font-size: 2rem
        h3
            font-size: 1.6rem
        h4
            font-size: 1.4rem
            
        &.boxed
            max-width: 720px
            margin: .6rem auto
            
                