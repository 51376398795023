.magazin
    position: relative
    padding: 0 0 4rem
    @media(max-width: 767px)
        padding-top: 8rem
    &__container
        @extend .container
        display: grid
        grid-column-gap: 1rem
        grid-template-columns: repeat(4, 1fr)
        grid-template-rows: auto
        grid-row-gap: 1rem
        
        @media(max-width: 1023px)
            grid-template-columns: repeat(3, 1fr)
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)