.hero
    position: relative
    background-color: $light
    background-size: cover !important
    background-repeat: no-repeat !important
    background-position: center !important
    height: 60vh
    min-height: 570px

    
    @media(max-width: 767px)
        min-height: 240px
        
    &__thumbnail
        position: absolute
        bottom: 0
        left: 50%
        transform: translate(-50%, 10%)
        z-index: 100
        @media(max-width: 767px)
            width: 80%
            
    &__shapes
        overflow: hidden
        width: 100%
        position: absolute
        bottom: 0
        left: 0
        height: 100%
        z-index: 20
        
        svg
            position: absolute
            bottom: 0%
            left: 0
            width: 100%
            display: block
            fill: $bodyBackgroundColor
            &.background
                opacity: .4