.product
    position: relative
    @media(max-width: 767px)
        padding-top: 8rem
    &__container
        @extend .container
    &__breadcrumb
        margin: 2rem 0
    &__columns
        display: grid
        grid-template-columns: 40% 60%
        grid-column-gap: 2rem
        @media(max-width: 1023px)
            display: block
        
    &__images
        &--content
            padding: 2rem
            position: sticky
            align-self: flex-start
            top: 0
            @media(max-width: 1023px)
                padding: 0
        &--thumbnail
            img
                margin: 0px auto
                border-radius: 4px
                transition: height .2s ease
                @media(max-width: 767px)
                    width: 100%
                    max-width: 240px
                
        &--gallery
            margin-top: 2rem
            display: grid
            grid-template-columns: repeat(5, 1fr)
            grid-column-gap: .4rem
            
            img
                max-height: 40px
                height: auto
                border-radius: 4px
                cursor: pointer
                margin: 0px auto
                
    &__description
        span
            display: block
            margin: 0 0 -1rem 0
        h1
            font-size: 4rem
            margin: 0
            
        ul
            padding: 0
            margin: 0
            list-style: none
            
            li
                list-style: none
                display: flex
                align-items: center
                margin: .2rem 0
                
                svg
                    display: block
                    height: 24px
                    width: auto
                    fill: $green
                    margin-right: 1rem
                    
        &--meta
            font-size: 1.6rem
            margin: 2rem 0rem 1rem
            small
                display: block
                text-align: left
                color: rgba($dark, .4)
                font-size: .8rem
                margin: 0
                line-height: 1
                p
                    span
                        display: inline-block
                        
                &.costs
                    max-width: 320px
                    line-height: 1.2
                    margin: 1rem 0
        form
            margin-top: 2rem
            
        &--payments
            display: flex
            align-items: center
            justify-content: flex-start
            max-width: 340px
            margin-top: 1.6rem
            
            span
                padding: .2rem 1rem
                opacity: .1
                margin: 0
                @media(max-width: 767px)
                    padding: .2rem .4rem
                svg
                    height: 24px
                    width: auto
                    display: block
                    @media(max-width: 767px)
                        height: 20px
    
        &--content
            margin-top: 2rem
            padding: 2rem 0rem 4rem
            
    &__similar
        @extend .container
        margin: 4rem auto
        h2
            font-size: 3rem
            text-align: center
            
        p
            margin: 1rem auto
            max-width: 720px
            text-align: center
        
        &--grid
            margin: 4rem auto
            display: grid
            grid-template-columns: repeat(3, 1fr)
            grid-row-gap: 2rem
            grid-column-gap: 2rem
            grid-template-rows: auto
            @media(max-width: 767px)
                grid-template-columns: repeat(3, 200px)
                width: 100%
                overflow-x: scroll
                margin-right: 4rem
                -ms-overflow-style: none
                scrollbar-width: none
                overflow: -moz-scrollbars-none
                &::-webkit-scrollbar
                    display: none
        
    
        