.about
    position: relative
    padding: 4rem 0
    @media(max-width: 767px)
        padding: 2rem 0 2rem
    &__container
        @extend .container
        
        h1
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 3rem
            
        p
            max-width: 800px
            margin: 1rem auto
            
    &__columns
        margin-top: 4rem
        display: grid
        grid-template-columns: calc(40% - 4rem) 60%
        grid-column-gap: 4rem
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)
            
        
    &__thumbnail
        @media(max-width: 767px)
            margin-bottom: 1rem
        img
            border-radius: 4px
            @media(max-width: 767px)
                width: 100%
    &__content
        h2
            font-size: 3rem
            max-width: 600px
            @media(max-width: 767px)
                font-size: 2rem
        
        p
            max-width: 600px
            margin: 1rem 0