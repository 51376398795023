.reviews
    position: relative
    margin-top: 4rem
    margin-bottom: 4rem
    &__container
        @extend .container
    
    &__header
        margin-bottom: 2rem
        h2
            font-size: 4rem
            text-align: center
            @media(max-width: 767px)
                font-size: 3rem
        
    &__item
        width: 80%
        margin: 0px auto
        text-align: center
        max-width: 600px
        @media(max-width: 767px)
            width: 100%
            
        
        &--rating
            margin-bottom: 1rem
            svg
                display: inline-block
                height: 16px
                width: auto