.advantages
    position: relative
    margin-top: 6rem
    &__container
        @extend.container
        
    &__title
        text-align: center
        border-top: 2px solid $light
        h3
            display: inline-block
            font-size: 2rem
            position: relative
            top: -2.2rem
            background: $bodyBackgroundColor
            padding: .4rem 1rem
            
    &__grid
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-column-gap: 2rem
        padding: 0 0 4rem
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)
            grid-row-gap: 2rem
        &--box
            text-align: center
            svg
                display: block
                fill: $green
                width: 80px
                height: auto
                max-height: 40px
                margin: 0px auto 1.6rem
                
            strong
                width: 100%
                text-align: center
                display: block
                color: $green
                
            p
                width: 90%
                margin: .6rem auto