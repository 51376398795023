.tabs
    position: relative
    &__header
        border-bottom: 2px solid $light
        display: flex
        align-items: center
        justify-content: flex-start
        
        &--trigger
            position: relative
            top: 2px
            padding: .4rem .6rem
            color: $grey
            cursor: pointer
            border-bottom: 2px solid transparent
            
            &.active
                color: $brand
                border-bottom: 2px solid $brand
                
    &__body
        
        position: relative
        transition: height .2s ease
        min-height: 300px
        &--content
            position: absolute
            top: 0
            left: 0
            width: 100%
            opacity: 0
            padding: .6rem
            
            &.active
                opacity: 1