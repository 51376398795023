.checkout
    position: relative
    
    &__container
        @extend .container
        padding: 2rem 0 4rem
        .woocommerce
            .woocommerce-NoticeGroup
                position: fixed
                bottom: 1rem
                right: 1rem
                z-index: 200
                max-width: 500px
            .wc_payment_methods
                li
                    label
                        display: inline-block
                        
                        img
                            display: inline-block
                            margin-right: .8rem !important
                        a
                            line-height: unset !important
                        
                    .payment_box
                        margin: 0 0 .4rem 0 !important
            .woocommerce-form-coupon-toggle
                display: none
                
            .woocommerce-checkout
                display: grid
                grid-template-columns: calc(60% - 4rem) 40%
                grid-column-gap: 4rem
                @media(max-width: 1023px)
                    grid-template-columns: calc(50% - 2rem) 50%
                    grid-column-gap: 2rem
                @media(max-width: 767px)
                    grid-template-columns: repeat(1, 1fr)
                #place_order
                    @extend .button
                
                #order_payment_heading,
                #order_review_heading
                    font-size: 2rem !important
                    margin: 0 0 1rem 0
                
                #customer_details
                    display: flex
                    flex-direction: column
                    
                    .col-1,
                    .col-2
                        width: 100%
                        
                        .form-row
                            margin: 0 0 .4rem
                            label
                                margin: 0
                            em
                                font-size: .8rem
                            
    
                    h3
                        font-size: 2rem
                        
                        label
                            font-size: 1.6rem