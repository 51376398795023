.shop
    position: relative
    margin-top: 4rem
    
    &__container
        @extend .container
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-row-gap: 2rem
        grid-column-gap: 2rem
        grid-template-rows: auto
        
        @media(max-width: 767px)
            grid-template-columns: repeat(3, 200px)
            width: 100vw
            overflow-x: scroll
            margin-right: 4rem
            -ms-overflow-style: none
            scrollbar-width: none
            overflow: -moz-scrollbars-none
            &::-webkit-scrollbar
                display: none
                
        &.slider
            display: block