.recipe
    position: relative
    @media(max-width: 767px)
        padding-top: 8rem
    &__container
        @extend .container
    
    &__breadcrumb
        margin: 2rem 0
        @media(max-width: 767px)
            font-size: .8rem
        
    &__columns
        display: grid
        grid-template-columns: calc(40% - 4rem) 60%
        grid-column-gap: 4rem
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)
        
    &__thumbnail
        position: sticky
        top: 2rem
        align-self: flex-start
        @media(max-width: 767px)
            position: relative
            top: 0
            margin-bottom: 2rem
        img
            border-radius: 4px
            @media(max-width: 767px)
                width: 100%
            
            
    &__content
        h1
            line-height: 1
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 2rem
        strong
            display: block
            clear: both
        &--box
            margin: 2rem 0
            clear: both
            
            ul
                margin: 0
                
            p
                margin: .6rem 0
                
            h2
                font-size: 2rem
                
            h3
                font-size: 1.6rem
                
            blockquote
                padding: 1rem 2rem
                margin: 0 auto
                display: inline-block
                text-align: center
                position: relative
                &::before
                    content: '"'
                    position: absolute
                    top: .4rem
                    left: .4rem
                    font-size: 3rem
                    color: $brand
                &::after
                    content: '"'
                    position: absolute
                    bottom: -1.6rem
                    right: .4rem
                    font-size: 3rem
                    color: $brand
            img
                margin: 1rem auto
                border-radius: 4px
            .alignleft
                float: left
                margin: 2rem 2rem 2rem 0
            
                &::after,
                &::before
                    display: table
                    content: ''
                    clear: both
        
            .alignright
                float: right
                margin: 2rem 0 2rem 2rem
            
                &::after,
                &::before
                    display: table
                    content: ''
                    clear: both
            
    &__recommended
        clear: both
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-column-gap: 2rem
        grid-row-gap: 2rem
        margin: 2rem 0 4rem
        @media(max-width: 767px)
            grid-template-columns: repeat(1, 1fr)