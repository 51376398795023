.banner
    position: relative
    background-size: cover !important
    background-position: center !important
    background-repeat: no-repeat !important
    padding-top: 8rem
    padding-bottom: 8rem
    margin-top: 4rem
    margin-bottom: 4rem
    &::after
        content: ''
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-color: rgba($dark, .4)
        color: $white
        z-index: 1
    &__container
        @extend .container
        
    &__content
        position: relative
        z-index: 10
        h1, h2, h3, h4, h5, h6
            color: $white
            
        h2
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 3rem
        p
            color: $white
            font-size: 1.2rem
            
        a
            text-decoration: none
        
        