.contact
    position: relative
    
    &__container
        @extend .container
        
    &__title
        h1
            font-size: 4rem
            
    &__columns
        display: flex
        justify-content: flex-start
        align-items: flex-start
        padding: 2rem 0 4rem
        @media(max-width: 767px)
            flex-direction: column
        
    &__sidebar
        width: 240px
        padding: 0 2rem 0 0
        @media(max-width: 767px)
            display: none
        ul
            padding: 0
            margin: 0
            list-style: none
            
            li
                padding: .4rem
                display: block
                border-bottom: 2px solid $light
                position: relative
                &:last-of-type
                    border-bottom: unset
            
                &::after
                    content: ''
                    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7 10' fill='@{grey}' stroke='none'><path d='m6.225 4.61-4.45-4.45c-.103-.103-.24-.16-.387-.16s-.284.057-.387.16l-.327.327c-.213.213-.213.561 0 .774l3.736 3.737-3.741 3.741c-.103.103-.16.24-.16.387s.057.284.16.387l.328.327c.103.103.24.16.387.16s.284-.057.387-.16l4.454-4.454c.104-.104.16-.241.16-.388s-.057-.285-.16-.388z'/></svg>") no-repeat
                    background-size: 10px 10px
                    height: 10px
                    width: 10px
                    display: block
                    position: absolute
                    right: 0
                    top: calc(50% - 5px)
                    opacity: .2
                a
                    text-decoration: none
                    font-weight: 500
                    color: rgba($grey, .5)
        
                &:hover
                    a
                        color: $dark
                    &::after
                        opacity: 1
    &__content
        flex: 1
        