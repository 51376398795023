.product
    position: relative
    
    &__thumbnail
        text-align: center
        a
            display: block
            width: 100%
            text-align: center
            position: relative
            
            img
                height: auto
                width: 90%
                margin: 0px auto
                @media(max-width: 767px)
                    width: 100%
                    max-width: 160px
                
    &__body
        text-align: center
        padding: 1rem 0
        
        .title
            display: block
            text-decoration: none
            position: relative
            margin-bottom: 1rem
            &::after
                content: ''
                position: absolute
                bottom: 0
                left: 50%
                transform: translate(-50%, -50%)
                width: 60px
                height: 2px
                background: $brand
            h3
                font-size: 2.4rem
            
        p
            margin: 0 auto
            
        small
            display: block
            text-align: center
            color: $brand
            font-size: .8rem
            
        .button
            text-decoration: none
            display: inline-block
            margin-top: 1rem
            margin-bottom: 1rem
            border: 2px solid $brand
            padding: .4rem
            @media (max-width: 767px)
                font-size: .8rem
    
            span
                padding: .2rem .6rem
                @media(max-width: 767px)
                    padding: 0
                
                &:first-of-type
                    border-right: 2px solid $brand
                    @media(max-width: 767px)
                        border-right: unset