.topbar
    position: relative
    background: $light
    padding: .4rem 0rem
    color: $dark
    
    @media(max-width: 1023px)
        display: none
    
    &__container
        @extend .container
        display: flex
        align-items: center
        justify-content: space-between
        
    &__info
        display: flex
        align-items: center
        justify-content: flex-start
        font-size: .8rem
        
        svg
            display: block
            height: 20px
            width: auto
            margin-right: .8rem
            
        a
            margin-left: .4rem
    