.onlineshop
    position: relative
    @media(max-width: 767px)
        padding-top: 8rem
    &__container
        @extend .container
        
    &__title
        text-align: left
        h1
            font-size: 4rem
            @media(max-width: 767px)
                font-size: 3rem
        
    &__grid
        margin: 4rem 0
        display: grid
        grid-template-columns: repeat(3, 1fr)
        grid-column-gap: 2rem
        grid-row-gap: 2rem
        @media(max-width: 767px)
            grid-template-columns: repeat(2, 1fr)
            grid-column-gap: 1rem
            grid-row-gap: 1rem
            
            .product
                margin-top: 2rem
                padding-top: 0 !important